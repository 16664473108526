import Vue from 'vue';
import Router from 'vue-router';
import ViewerBox from './components/Viewer.vue';
import LoginPage from './components/Login.vue';
import store from './store';  // Import your Vuex store

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/app',
      name: 'app',
      component: ViewerBox,
      meta: { requiresAuth: true }  // Add a meta field to mark this route as protected
    },
    {
      path: '/',
      name: 'LoginPage',
      component: LoginPage
    },
  ]
});

router.beforeEach((to, from, next) => {
  console.log(`Navigating to: ${to.path}`);
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log("This route requires authentication");

    // Access the namespaced getter
    if (!store.getters['auth/isAuthenticated']) {
      console.log("User not authenticated, redirecting to LoginPage");
      next({ name: 'LoginPage' });
    } else {
      console.log("User authenticated, proceeding to route");
      next();  // Proceed to the route
    }
  } else {
    next();  // Always proceed if the route does not require authentication
  }
});

export default router;
