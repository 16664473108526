import { auth, signOut } from "../../firebase/config.js";
import router from '@/router';
import { onAuthStateChanged } from 'firebase/auth';

const getDefaultState = () => {
  return {
    firebaseAuth: auth,
    isAuthenticated: false,
    uid: "",
  };
};

export default {
  namespaced: true, // Ensure this module is namespaced

  state: getDefaultState(),

  mutations: {
    setLogin(state, { authStatus, uid }) {
      state.isAuthenticated = authStatus;
      console.log(state.isAuthenticated);
      state.uid = uid;
    },
    resetAuthState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  actions: {
    initAuthState({ commit }) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;
          commit('setLogin', { authStatus: true, uid: uid });
        } else {
          commit('resetAuthState');
        }
      });
    },

    logOut({ commit }) {
      signOut(auth)
        .then(() => {
          commit("resetAuthState");
          sessionStorage.clear();
          router.push('/');
        })
        .catch(error => {
          console.log(error);
          commit("resetAuthState");
          sessionStorage.clear();
          router.push('/');
        });
    },
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getUID(state) {
      return state.uid;
    },
  }
};
