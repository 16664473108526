<template>
<v-app>
    <v-main>
        <v-container class="d-flex fill-height align-center">
            <v-row class="login-row justify-center mt-10">
                <v-col cols="12" sm="8" md="4" lg="4">
                    <v-img class="mx-auto mb-10" src="source_logo_white.png" alt="" contain height="100" width="85%"></v-img>
                    <v-card class="mx-auto pa-12 pb-8" color="rgba(209, 213, 218, 0.1)" elevation="2" max-width="500" rounded="lg">
                        <v-form @submit.prevent="submit">
                            <div class="text-subtitle-1 text-medium-emphasis">Account</div>
                            <v-text-field required color="#33ff33" v-model="email" density="compact" placeholder="Enter your email address" prepend-inner-icon="mdi-email-outline" variant="outlined"></v-text-field>
                            <div color="#33ff33" class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                                Password
                                <a class="text-caption text-decoration-none text-blue" rel="noopener noreferrer">
                                    Forgot password?</a>
                            </div>
                            <v-text-field required color="#33ff33" v-model="password" :append-icon="visible?'mdi-eye':'mdi-eye-off'" density="compact" placeholder="Enter your password" prepend-inner-icon="mdi-lock-outline" variant="outlined" :type="visible ? 'text' : 'password'" @click:append="visible = !visible"></v-text-field>
                            <v-btn block type="submit" class="mb-8 mt-2" color="secondary" size="large" variant="tonal">
                                Log In
                            </v-btn>
                        </v-form>
                        <v-btn block @click="signInWithGoogle" size="large" variant="tonal" color="brown darken-2" light outlined class="mt-n5 mb-2">
                            <img src="google.svg" height="20px" class="mr-2"> <span>Continue with Google</span>
                        </v-btn>
                        <v-col class="justify-left mb-0">
                            <p class="nowrap conditions ml-n2">
                                By continuing you agree to Proofcheck GmbH's
                                <router-link class="ml-0" to="/terms">Terms & Conditions. </router-link>
                            </p>
                        </v-col>
                    </v-card>
                    <v-spacer />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
    <v-alert v-model="alert" dismissible border="left" elevation="2" colored-border class="border-alert">
        {{ errorMessage }}
    </v-alert>
</v-app>
</template>

<script>
import {
    auth,
    signInWithEmailAndPassword,
    signInWithPopup,
    googleProvider
} from "../../firebase/config.js";

import router from "@/router";


export default {
    name: "LoginPage",
    data() {
        return {
            dialog: false,
            auth: auth,
            email: "",
            password: "",
            alert: false,
            errorMessage: "",
            showForgot: true,
            visible: false,
        };
    },
    methods: {
        resetState() {
            this.showForgot = true
        },

        mounted() {
            this.resetState()
        },

        deactivate() {
            this.resetState()
        },

        destroyed() {
            this.resetState()
        },

        async sleep() {
            console.log('Before sleep');
            await new Promise(resolve => setTimeout(resolve, 5000)); // Sleep for 5 seconds
            console.log('After sleep');
        },
        async postSign(uid) {    
            this.$store.commit("auth/setLogin", {
                authStatus: true,
                uid: uid,
            });
            console.log("signed in successfully");
            // go to bookshelf
            // Attempt to navigate to '/app'


            setTimeout(() => {
            // Go to bookshelf
            router.push("/app")
                .catch(err => {
                    console.error("Router navigation error:", err);
                });
        }, 200); // Slight delay to make sure Vuex has updated state
        },

  

        async signInWithGoogle() {
            try {
                const result = await signInWithPopup(auth, googleProvider);
                const user = result.user;

                console.log(result);
                console.log(user.uid);

                const {
                    uid,
                } = user;
                await this.postSign(uid);

            } catch (error) {
                console.log(error)
                const errorMessage = error.message;
                // this.errorMessage = errorMessage;
                this.errorMessage = error.message;
                this.alert = true;
                console.log(errorMessage);
            }
        },
        async submit() {
            // sign in
            try {
                let userCredential = await signInWithEmailAndPassword(
                    this.auth,
                    this.email,
                    this.password
                );

                // get username
                let uid = userCredential.user.uid;
                console.log("starting auth")
                await this.postSign(uid)
            } catch (error) {
                const errorMessage = error.message;
                // this.errorMessage = errorMessage;
                this.errorMessage = "Invalid Email-Password Combination.";
                this.alert = true;
                console.log(errorMessage);
            }
        },
    },
};
</script>

<style scoped>

.text-subtitle-1 {
    font-weight: 600 !important;  /* Make the font weight lighter */
    color: #33ff33 !important; /* Change color to a lighter shade */
  
}

.home {
    font-size: 23px !important;
    font-weight: 300 !important;
    font-family: "Kumbh Sans", sans-serif !important;
}

.closebtn {
    color: black !important;
    position: relative;
    bottom: 200px !important;
    left: 5% !important;
    font-size: 20px;
}

.resetbtn {
    position: relative;
    bottom: 0px !important;
    left: -3% !important;
    /* font-size: 10px !important; */
}

.v-application .primary {
    background-color: #38536d !important;
    border-color: #38536d !important;
}

.policy {
    /* white-space: nowrap !important; */
    /* overflow: hidden !important; */
    /* position: absolute !important; */
    font-size: 15px !important;
}

.conditions {
    font-size: 12px !important;
    /* font-style: italic !important; */
}

.routerLink {
    /* text-decoration: underline !important; */
    text-decoration-color: white;
}

.border-alert {
    border-color: rgb(94, 140, 148);
    /* Set your RGB color */
    border-width: 2px;
    /* Set border width as needed */
    border-style: solid;
    /* Required to make the border visible */
    z-index: 1000;
    /* You can adjust this value as needed */
    position: relative;
}

.translucent-card {
    /* background-color: rgba(209, 213, 218, 0.1);
    /* semi-transparent white */
    /* light gray border */
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.login-row {
    position: relative !important;
    margin-bottom: 8% !important;
}


input::placeholder {
    color: red !important;
}

.v-text-field >>> input::placeholder {
    color: #33ff33 !important;  /* Set to red just for testing; change as necessary */
    opacity: 1 !important;
}

.theme--light.v-input >>> input, .theme--light.v-input >>> textarea {
    color: #33ff33 !important;  /* Set to red just for testing; change as necessary */
    opacity: 0.7 !important;
}

</style>
