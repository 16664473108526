import { initializeApp } from "firebase/app";
import {
  getAuth, createUserWithEmailAndPassword,
  signInWithEmailAndPassword, sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  signOut
} from "firebase/auth";

import {
  getFirestore, doc, collection, addDoc,
  deleteDoc, getDoc, getDocs, setDoc, updateDoc
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";

// install auth plugin
const firebaseConfig = {
  apiKey: "AIzaSyAFe0VTh1FXN2xkDuYMrzW-FV2du2IAL_A",
  authDomain: "proofcheck-b9ec8.firebaseapp.com",
  projectId: "proofcheck-b9ec8",
  storageBucket: "proofcheck-b9ec8.appspot.com",
  messagingSenderId: "676050159160",
  appId: "1:676050159160:web:a15cb902332a41a444c8cd",
  measurementId: "G-10L2YZS83N"
};
// Initialize Firebase
initializeApp(firebaseConfig);

const auth = getAuth()
const googleProvider = new GoogleAuthProvider();
const db = getFirestore()
const storage = getStorage()


export {
  auth, db, storage, googleProvider, ref, listAll, doc, collection, getDocs, deleteDoc, deleteObject,
  setDoc, addDoc, updateDoc, getDoc, uploadBytes, getDownloadURL,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail, signOut
}