<template>
  <div class="page zoom-85">
    <img
      height="10px"
      src="source_logo_white.png"
      class="logo"
    />

      <a-button type="primary" @click="signOut" class="sign-out-button">
      Sign Out
    </a-button>
    <br>
    <span class="demo" @click="toggleDemo">

     <span class="title">Title </span><br>
        <span class="star">
        
        <a href=https://docs.google.com/document/d/1LMa2KszrufbJ7LGYVB2M0muauXlxQD_1XBIV8AsCMdM/edit target="_blank">
        Demo Proof
        </a>
        </span> <br><br>


    <span class="title">I. Direction </span><br>
        <span class="demo star" v-if="isDemoCSelected">*</span>
        <span class="star">Encode | </span>
      </span>
    <span class="demo" @click="toggleDemo">
      <span class="demo star" v-if="!isDemoCSelected">*</span>
      <span class="star">Decode</span>
    </span>
    <br>
    <br>
    
    <span v-if="isDemoCSelected" class="demo"> <span class="title">II. Protection Level <br> </span>
      <span class="demo star" v-if="level=='pdf'">*</span><span class="star " @click="setLevel('pdf')">Text Attribution | </span>
      <span/><span class="demo star" v-if="level=='llm'">*</span><span class="star"  @click="setLevel('llm')">LLM Resistance </span>
    </span>

    <br>
    <br>
    
    <span v-if="isDemoCSelected" class="demo">
        <span class="title">III. Title Profile<br></span>
        <span @click="setProfile(profile)" class="demo star" v-for="(profile, index) in profiles" :key="profile">
            <span class="demo star" v-if="selectedProfile==profile">*</span>{{ profile.org }}<span v-if="index !== profiles.length - 1"> | </span>
        </span>
    </span>

    <a-modal v-model="profileModalVisible" 
        title="Edit Profile" 
        @ok="profileModalVisible = false" 
        @cancel="profileModalVisible = false"
        class="custom-modal"
    >

    <a-form
      :model="selectedProfile"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      label-align="left"
      ref="profileForm"
    >
      <a-form-item
        v-for="(value, key) in selectedProfile"
        :key="key"
        :label="formatLabel(key)"
        :name="key"
      >
        <a-input v-model="selectedProfile[key]" />
      </a-form-item>
    </a-form>


    
      <!-- <pre>{{ formattedProfile }}</pre> -->

    <template #footer="{ }">
    <a-button @click="profileModalVisible = false" type="primary">OK</a-button>
    <a-button @click="profileModalVisible = false">Cancel</a-button>
  </template>
    </a-modal>

    <span v-if="isDemoCSelected">
    <br>
    <br> 
    </span>

  
    <div class="container">   
      <a-textarea 
        v-model="text" 
        :readonly="!isDemoCSelected"
        @keydown.enter="onKeyDown" 
        @paste="handlePaste" 
        class="text-input width-fixed" 
        placeholder="Paste here">
      </a-textarea> 

      <a-card :headStyle="headStyle" :loading="loading" :title="cardTitle" class="width-fixed">
      <template #extra></template>
       <a-list v-if="!isDemoCSelected" :data-source="comments">
          <a-list-item 
            v-for="(comment, index) in comments" 
            :key="index"
            class="comment-item"
          >
            <a-list-item-meta>

            <br>
            <br>
            <template #description>
                <span>[Copied Text Fragment] - </span>

                <b>{{ comment.text }}</b>
                <br>

                <span class="source"> [Source Text] 
                  <a @click="expanded = !expanded">{{ expanded ? '-' : '+' }}</a>
                  <span v-if="!expanded"></span>
                  <span v-else>{{ comment.original_text }}</span>
                </span>
             </template>

              
              <template #title>
              <a-button
              type="danger"
              @click="deleteComment(index)"
              class="delete-button"
            >
              Delete
            </a-button>
              <div><a-tag color="green">Organization:</a-tag><span>{{ comment.org }}</span></div>
              <div><a-tag color="lime">Person:</a-tag><span><b>{{ comment.name }}</b></span></div>
              <div><a-tag color="gold">Role:</a-tag><span>{{ comment.role }}</span></div>
              <div><a-tag color="orange">Email:</a-tag><span>{{ comment.email }}</span></div>
              <div><a-tag color="volcano">ISBN:</a-tag><span>{{ comment.isbn }}</span></div>
              <div><a-tag color="volcano">Title:</a-tag><span>{{ comment.title }}</span></div>
              <div><a-tag color="volcano">Date:</a-tag><span>{{ comment.datetime }}</span></div>
              <div><a-tag color="red">Fingerprint:</a-tag><span>{{ comment.hash }}</span></div>
              <div><a-tag color="magenta">Warning Message:</a-tag><span>{{ comment.warning_message }}</span></div>
              <div><a-tag color="magenta">License:</a-tag><span>{{ comment.license }}</span></div>
              <div><a-tag color="volcano">URL:</a-tag><span><a>{{ comment.url }}</a></span></div>
              </template>
            </a-list-item-meta>

            <!-- <a-button
              type="danger"
              @click="deleteComment(index)"
              class="delete-button"
            >
              Delete
            </a-button> -->
          </a-list-item>
        </a-list>

        <div v-else>
        <p class="text-output"> {{cText}} </p>
        </div>
        <!-- <a-textarea v-else v-model="cText" 
          class="text-output width-fixed" 
          placeholder="Output copied to clipboard" readonly></a-textarea>  -->
          </a-card>
          
        </div>
      </div>
</template>

<script>
import axios from 'axios';
import { Modal, Button, Input, Form } from 'ant-design-vue';

const apiURL = process.env.VUE_APP_API_URL;


export default {
  name: 'ViewerBox',
  props: {
    msg: String
  },
  mounted(){
     this.getProfiles()
  },
  components: {
    'a-modal': Modal, 
    'a-button': Button, 
    'a-form': Form,
    'a-input': Input,
    'a-form-item': Form.Item,
  },
  data() {
    return {
      text: '',
      cText: '',
      comments: [],
      profiles: [], 
      selectedProfile: "Alex",
      headStyle: {"color":"white", "font-size": "20px"},
      loader: false,
      isDemoCSelected: true,
      level: "pdf",
      profileModalVisible: false,
      expanded: false, 
    };
  },
  methods: {
     signOut() {
      this.$store.dispatch('auth/logOut') // Dispatch the logOut action
        .then(() => {
          console.log("Logged Out") // Navigate to login page
        })
        .catch(error => {
          console.error("Error during sign out:", error);
        });
    },
   onKeyDown(event){
     if (event.key === 'Enter' && event.shiftKey){
      this.submitText()
      }
     },
   handlePaste(event) {
      event.preventDefault();
      this.text = event.clipboardData.getData('text');
      this.submitText()
    },
    submitText(){
      if (!this.isDemoCSelected){
          this.submitTextD()
      } else {
        this.submitTextC()
      }
    },
    async copyTextToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        console.log('Text copied to clipboard');
        // Optionally, you can emit an event or call a method here to notify the user that the text was copied successfully
      } catch (err) {
        console.error('Failed to copy text: ', err);
        // Handle the error here (e.g., notification to the user that copying failed)
      }
    },
    toggleDemo() {
      this.isDemoCSelected = !this.isDemoCSelected;
    },
    deleteComment(index) {
      this.comments.splice(index, 1);
    },

    async getProfiles(){
      try {
        console.log(`${apiURL}/profiles`)
        const response = await axios.get(`${apiURL}/profiles`);
        this.profiles = [...response.data.profiles]
        this.selectedProfile = this.profiles[0]

      } catch (error) {
        console.error("There was an error getting the profiles:", error);
        this.profiles = []
      }
    },

    async submitTextD() {
      try {
        this.loader = true;
        const response = await axios.post(`${apiURL}/decipher`, { text: this.text });
        this.comments.push(...response.data.comments);
         setTimeout(() => { this.text = ''
                            this.loader = false;
         }, 1000);
      } catch (error) {
        console.error("There was an error posting the text:", error);
      }
    },

  async setLevel(newLevel){
      this.level = newLevel
      // this.selectedProfile = {...this.selectedProfile, level:newLevel};
      // this.selectedProfile = {...this.selectedProfile};
      try {
        // this.loader = true;

        const newProfile = {...this.selectedProfile, level:newLevel};
        const response = await axios.post(`${apiURL}/set_profile`, { profile: newProfile });
        console.log(response);
        // this.loader = false;
      } catch (error) {
        console.error("There was an error posting the text:", error);
      }
    },

    async setProfile(newProfile){
      this.profileModalVisible = true;  
      this.selectedProfile = newProfile
      console.log(newProfile);

      try {
        this.loader = true;
        const response = await axios.post(`${apiURL}/set_profile`, { profile: this.selectedProfile });
        console.log(response);
        this.loader = false;
      } catch (error) {
        console.error("There was an error posting the text:", error);
      }
    },
    async submitTextC(){
       try {
        this.loader = true;
        const response = await axios.post(`${apiURL}/cipher`, { text: this.text });
        const cipher = response.data.cipher
        await this.copyTextToClipboard(cipher)
        // this.comments.push(...response.data.comments);
        console.log(response)
         setTimeout(() => { this.text = '';
                            this.cText = cipher;
                            this.loader = false;
         }, 1000);
      } catch (error) {
        console.error("There was an error posting the text:", error);
      }
    },
    formatLabel(key) {
      return key.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());
    },
  }, 
  computed: {
     loading() {
      // The loading state is true when there are no comments
      console.log(this.loader)
      return this.loader;
    },
    cardTitle(){
      return this.isDemoCSelected ? "Text Copied to Clipboard": "Textual Provenance"
    }, 
    formattedProfile() {
      return JSON.stringify(this.selectedProfile, null, 2);
    },
  
  }
}
</script>


<style scoped>

.zoom-80 {
  transform: scale(0.8); /* Scales the component to 80% */
  transform-origin: top left; /* Optional: Adjust the point where scaling originates */
}

.sign-out-button {
  position: absolute; /* Place the button in a fixed position */
  top: 20px; /* Distance from the top of the page */
  right: 20px; /* Distance from the right edge of the page */
  background-color: purple; /* Red color for sign out */
  border-color: purple;
  color: white;
}

.sign-out-button:hover {
  background-color: #c9302c; /* Darker red on hover */
  border-color: #ac2925;
}

.ant-form-item {
  margin-bottom: 0; /* Remove space between form items */
}

/* Optional: Custom styles for modal size */
.custom-modal .ant-modal {
  width: 80vw !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.ant-form-item-control-input-content {
  background: rgba(250, 250, 250, 0.5) !important; /* Light background with some transparency */
}

.logo {
  position: relative !important;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  left: 2%; /* Center align if within a flex container or block layout */
  
}

h2 {
  color: white;
  margin-bottom: 0 !important;
}

.demo {
  color: #FAC000 !important;
  font-size: 20px !important;
}

.star{
  color:#33ff33 !important;

}
.page {
  background-color: #0f0f0f; /* Dark background for the 'hacker' look */
  color: #33ff33; /* Classic green text color */
  font-family: 'Consolas', 'Monaco', monospace;
}


.page img {
  position: relative !important;
  top: 0;
  margin-left:-50px !important;
  width: 500px;
  height: auto; /* Maintain aspect ratio */
  -webkit-mask-image: radial-gradient(circle, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
  mask-image: radial-gradient(circle, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
}

.title {
  font-size: 24px !important;
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: flex-start; /* Align items to the start of the container vertically */
  width: 50vw;
  margin: 0 auto;
  margin-top: 0; /* No space above the container */
  padding-top: 0; /* No padding at the top of the container */
}


.width-fixed {
  width: 50vw; /* Set the width to 50% of the viewport width */
  margin: auto; /* Center align */
  box-sizing: border-box;
}

.ant-card, .ant-input-textarea {
  padding: 0 !important; /* Ensure no padding is applied */
  margin: 0 !important;  /* Ensure no margin is applied */
  width: 100%; /* Make sure they take the full width of the container */
  box-sizing: border-box; /* Ensure consistent sizing with padding and borders */
}


@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #33ff33; }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.toggle2 {
  margin-left: 10px !important;
}

.text-input {
  width: 100%; /* Take the full width of its parent */
  min-height: 20vh; /* Minimum height */
  padding: 0.5rem;
  border: 1px solid #ccc;
  resize: vertical; /* Allows vertical resizing */
  margin-bottom:0 !important;
  margin-top: 0 !important;
  background-color: #000; /* Slightly lighter black for contrast */
  color: #33ff33;
  border: 1px solid #33ff33;
  /* "Blinking cursor" effect */
  animation: blink-caret 0.5s step-end 5;
}

.text-output {  
  background-color: #000; /* Slightly lighter black for contrast */
  color: #33ff33;
  /* "Blinking cursor" effect */
}

 .ant-card {
  margin-top: 10px !important; /* Overrides any existing margin */
  background-color: black !important;
}

 .ant-textarea {
  margin-bottom: 0 !important; /* Overrides any existing margin */
}

.ant-card-head-title {
  color: #FAC000 !important;
}

.ant-list-item-meta-title {
  color:white !important;
}

.ant-list-item-meta-description{
  color:#FAC000 !important;

}

.ant-list-item-meta-description .source{
  color:#33ff33 !important;

}

.ant-card-head-title {
  color:white !important;
}

.comment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #33ff33;
  color: white !important;
}



.tag-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center align the flex items */
  margin-bottom: 4px; /* Space between each tag-container */
}

.ant-tag {
  flex-shrink: 0; /* Prevent the tag from shrinking */
  width: 100px; /* Set a fixed width for all tags */
  text-align: center; /* Center the text inside the tag */
  margin-right: 8px; /* Spacing between the tag and the span */
}


.ant-tag {
  display: inline-flex;   /* Allows the content to grow as needed */
  align-items: center;    /* Centers the content vertically */
  justify-content: right;/* Centers the content horizontally */
  min-width: 150px;       /* Minimum width to start with */
  padding: 0 1px;        /* Padding on sides for some breathing space */
  text-align: center;     /* Ensures text is always centered if there's space */
  white-space: nowrap;    /* Prevents the tag content from wrapping */
  overflow: hidden;       /* Hides any overflowing content */
  margin-right: 5px;      /* Spacing between tags */
  color: black;
}

span {
  flex-grow: 1; /* Allow the span to take up the remaining space */
}

/* If you wrapped each tag with a div as previously suggested */
.ant-list-item-meta-title div {
  margin-bottom: 2px; /* Spacing between tags */
}

.ant-list-item-meta-title a-tag {
  display: block; /* Make each tag display as a block element */
  margin-bottom: 8px; /* Optional: Adds space between the tags */
}



/* Clear float */
.comment-item:after {
  content: "";
  display: table;
  clear: both;
}


.delete-button {
  float: right; /* Positions the button to the right */
  margin-left: 10px; /* Adds some space between the button and text */
  background-color: #D32F2F;
  border-color: #D32F2F;
  /* Hover effect */
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #ff5555;
}

/* Ensure the avatar and text are clearly visible */
.a-avatar, .ant-list-item-meta {
  color: #33ff33;
}

/* Remove list padding and set list background */
.ant-list-items {
  background-color: #0f0f0f;
}

/* Override Ant Design's list item padding for full-width lines */
.ant-list-item {
  padding-left: 0;
  padding-right: 0;
}

/* Clear floating elements */
.comment-item:after {
  content: "";
  display: table;
  clear: both;
}

.code-animation {
  display: inline-block; /* Make the span inline-block to enable width animation */
  overflow: hidden; /* Hide the overflow to create the typing effect */
  white-space: nowrap; /* Keep the text in one line */
  border-right: 2px solid #33ff33; /* Cursor style */
  width: 0; /* Start with a width of 0 */
  max-width: max-content;
  animation: 
    typing 4s steps(40, end) 1s forwards, /* 40 steps for the example text length */
    blink-caret 1s step-end infinite;
}

 pre {
    background-color: #f0f0f0;
    color: #333;
    padding: 15px;
    border-radius: 5px;
    overflow: auto;
  }

.custom-modal .ant-modal {
  width: 80% !important; /* Override the default width */
  background-color: rgba(255, 255, 255, 0.5) !important; /* Make the background nearly transparent */
}


</style>
