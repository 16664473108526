import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css'; // Import Ant Design styles
import vuetify from './plugins/vuetify'; // Import Vuetify plugin configuration
import router from './router'; // Import Vue Router
import store from './store'; // Import Vuex store (root store which includes the auth module)

// Use Ant Design Vue
Vue.use(Antd);

// Disable production tip
Vue.config.productionTip = false

// Create Vue instance
new Vue({
  router,
  store, // Add Vuex store (which includes auth) to the Vue instance
  vuetify, // Add Vuetify to the Vue instance
  created() {
    // Initialize authentication state on app load
    this.$store.dispatch('auth/initAuthState'); // Dispatch the initAuthState from the auth module
  },
  render: h => h(App), // Render the App component
}).$mount('#app');
