<template>
  <div id="app">
    <!-- <ViewerBox msg="decyphr"/> -->

    <router-view></router-view>
  </div>
</template>

<script>
// import ViewerBox from './components/Viewer.vue'

export default {
  name: 'App',
  components: {
    // ViewerBox
    // DocxViewer
  }
}
</script>

<style>

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #0f0f0f; /* or your desired background color */
}

#app, .container {
  min-height: 100vh; /* Minimum height of the viewport */
  width: 100%; /* Full width of the viewport */
  margin: 0;
  padding: 0;
  /* Additional styles to center content, if desired */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center; /* Center content horizontally */
  background-color: #0f0f0f;
}

</style>
